<template>
  <v-container fluid class="mobile-actions-container">
    <v-slide-y-transition>
      <v-row no-gutters class="cancel-row" v-if="showCancelButton">
        <v-btn
          dense
          small
          block
          color="red"
          class="white--text"
          @click="cancel"
        >
          X
        </v-btn>
      </v-row>
    </v-slide-y-transition>
    <v-row no-gutters align="center">
      <v-col cols="9">
        <mobile-floor-actions />
      </v-col>
      <v-spacer />
      <v-col cols="auto" class="filter-col">
        <v-menu
          v-model="filterActive"
          transition="slide-y-transition"
          content-class="menu-content"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              class="default-action mb-0"
              :style="{ backgroundColor: filterActive ? '#000000' : '#ffffff' }"
              elevation="5"
              v-on="on"
              v-bind="attrs"
            >
              <v-icon
                class="mt-2"
                :color="filterActive ? 'white' : destakColor"
              >
                {{ filterActive ? "mdi-close" : "mdi-filter-outline" }}
              </v-icon>
              <v-row
                no-gutters
                class="subtitle"
                :class="{ 'subtitle-active': filterActive }"
                :style="{ color: destakColor }"
              >
                {{ filterActive ? "Fechar" : "Filtros" }}
              </v-row>
            </v-sheet>
          </template>
          <v-sheet
            class="default-action"
            :style="{
              backgroundColor: isShowingElevators ? backgroundColor : '#ffffff',
            }"
            elevation="5"
            @click="toggleElevators"
          >
            <SVGLoader
              selected-icon="elevator"
              class="default-svg-mobile"
              :style="{ color: isShowingElevators ? '#ffffff' : destakColor }"
            />
            <v-row
              no-gutters
              class="subtitle"
              :class="{ 'subtitle-active': isShowingElevators }"
              :style="{ color: destakColor }"
            >
              Elevadores
            </v-row>
          </v-sheet>
          <v-sheet
            class="default-action"
            :style="{
              backgroundColor: isShowingStairs ? backgroundColor : '#ffffff',
            }"
            elevation="5"
            @click="toggleStairs"
          >
            <SVGLoader
              selected-icon="escalator"
              class="default-svg-mobile"
              :style="{ color: isShowingStairs ? '#ffffff' : destakColor }"
            />
            <v-row
              no-gutters
              class="subtitle"
              :class="{ 'subtitle-active': isShowingStairs }"
              :style="{ color: destakColor }"
            >
              Escadas
            </v-row>
          </v-sheet>
          <actions-button
            v-if="showActions"
            class="default-action"
            type="camera"
            :inside="true"
            :active="isDefaultCamera"
            :bgColor="backgroundColor"
            :txtColor="destakColor"
            @click="toggleCamera"
          />
          <actions-button
            v-if="showActions"
            class="default-action"
            type="stream"
            :inside="true"
            :active="isPaused"
            :bgColor="backgroundColor"
            :txtColor="destakColor"
            @click="togglePause"
          />
          <actions-button
            v-if="showActions"
            class="default-action"
            type="speed"
            :inside="true"
            :bgColor="backgroundColor"
            :txtColor="destakColor"
            :showIconTitle="true"
            @click="$store.dispatch('changeSpeed')"
          />
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import MobileFloorActions from "@/components/floor/MobileFloorActions.vue";
import ActionsButton from "@/components/actions/utils/ActionsButton.vue";
import SVGLoader from "@/components/SVGLoader.vue";

export default {
  name: "MobileActions",
  components: { MobileFloorActions, ActionsButton, SVGLoader },
  data: () => ({
    filterActive: false,
  }),
  methods: {
    toggleFilter() {
      this.filterActive = !this.filterActive;
    },
    toggleCamera() {
      this.$store.commit("toggleCamera");
    },
    togglePause() {
      this.$store.commit("togglePause");
    },
    toggleElevators() {
      this.$store.commit("toggleElevators");
    },
    toggleStairs() {
      this.$store.commit("toggleStairs");
    },
    cancel() {
      this.$store.commit("setCancelRoute", true);
    },
  },
  computed: {
    showCancelButton() {
      return this.$store.getters.isInRoute && this.isMobile;
    },
    showActions() {
      return (
        this.$store.getters.getCodeMallData.box &&
        !this.$store.getters.hideRouteActions &&
        this.$store.getters.getMallData.webMapShowPersonage &&
        this.inRouteProgress
      );
    },
    backgroundColor() {
      return this.$store.getters.getMallData?.webButtonBackgroundColor;
    },
    destakColor() {
      return this.$store.getters.getMallData?.webDestakColor;
    },
    isDefaultCamera() {
      return this.$store.getters.isDefaultCamera;
    },
    isPaused() {
      return this.$store.getters.isPaused;
    },
    setSpeedIcon() {
      switch (this.$store.getters.getSpeed) {
        case 3:
          return "mdi-speedometer-slow";

        case 6:
          return "mdi-speedometer-medium";

        default:
          return "mdi-speedometer";
      }
    },
    isShowingElevators() {
      return this.$store.getters.isShowingElevators;
    },
    isShowingStairs() {
      return this.$store.getters.isShowingStairs;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    inRouteProgress() {
      return this.$store.getters.routeInProgress;
    },
  },
};
</script>
<style scoped>
.mobile-actions-container {
  position: relative;
  z-index: 2;
  padding: 0px 0px 12px 0px;
}
.hide-actions {
  display: none !important;
}
.cancel-row {
  background-color: red;
}
.actions-row {
  margin: 8px 16px 0px 0px;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 2;
}
.filter-col {
  margin-right: 12px;
}
.menu-content {
  padding: 12px 6px 12px 12px;
  margin: 60px 0px 0px 6px;
  box-shadow: none;
}
.default-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px !important;
  width: 60px !important;
  border-radius: 5px;
  margin-bottom: 12px;
}
.default-action:last-child {
  margin-bottom: 0px;
}
.subtitle {
  font-size: 12px;
  font-weight: 700;
  flex: 0;
}
.subtitle-active {
  color: #ffffff !important;
}
.default-svg-mobile {
  width: 20px;
  height: 20px;
  margin-bottom: 4px;
}
</style>
