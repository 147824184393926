<template>
  <v-container fluid class="action-button-container">
    <v-sheet
      class="default-action"
      :class="{ column: inside }"
      :style="{
        backgroundColor: active ? bgColor : '#ffffff',
      }"
      elevation="5"
      @click="$emit('click')"
    >
      <v-icon class="action-icon" medium :color="active ? 'white' : txtColor">
        {{ icon }}
      </v-icon>
      <v-row
        v-if="showIconTitle"
        no-gutters
        class="speed-text"
        :style="{ color: txtColor }"
      >
        {{ speedText }}
      </v-row>
      <v-row
        v-if="inside"
        no-gutters
        class="subtitle"
        :style="{ color: active ? '#ffffff' : txtColor }"
      >
        {{ buttonText }}
      </v-row>
    </v-sheet>
    <v-row
      v-if="!inside"
      no-gutters
      class="subtitle"
      :style="{ color: txtColor }"
    >
      {{ buttonText }}
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "ActionsButton",
  props: {
    type: {
      type: String,
      default: "camera",
      validator: (value) =>
        ["camera", "stream", "speed", "cancel"].includes(value),
    },
    active: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
    },
    txtColor: {
      type: String,
    },
    showIconTitle: {
      type: Boolean,
      default: false,
    },
    inside: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    icon() {
      switch (this.type) {
        case "stream":
          return this.active ? "mdi-pause" : "mdi-play";
        case "speed":
          return this.setSpeedIcon;
        case "cancel":
          return "mdi-close";

        default:
          return this.active ? "mdi-map-outline" : "mdi-run-fast";
      }
    },
    buttonText() {
      switch (this.type) {
        case "stream":
          return this.active ? "Pausar" : "Iniciar";
        case "speed":
          return "Velocidade";
        case "cancel":
          return "Cancelar";

        default:
          return this.active ? "Mapa" : "Câmera";
      }
    },
    speedText() {
      return this.$store.getters.getSpeed / 3 + "x";
    },
    setSpeedIcon() {
      switch (this.$store.getters.getSpeed) {
        case 3:
          return "mdi-speedometer-slow";

        case 6:
          return "mdi-speedometer-medium";

        default:
          return "mdi-speedometer";
      }
    },
  },
};
</script>
<style scoped>
.action-button-container {
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.default-action {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px !important;
  width: 60px !important;
  border-radius: 5px;
}
.action-icon {
  margin-bottom: 4px !important;
}
.column {
  flex-direction: column !important;
}
.subtitle {
  font-size: 12px;
  font-weight: 700;
  flex: 0;
}
.speed-text {
  position: absolute;
  top: 60%;
  bottom: 50%;
  font-size: 12px;
  font-weight: 700;
}

/* Mobile */
@media (max-width: 1079px) and (max-height: 1919px) {
  .speed-text {
    position: absolute;
    top: 4ch;
    font-size: 12px;
    font-weight: 700;
  }
}
</style>
