import Vue from "vue";
import Vuex from "vuex";
import route from "./modules/route";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    codeMall: null, // Codigo do shopping informado
    mallData: null, // Todas as informações do shopping (andares, lojas, logos e etc...)
    currentFloor: null, // Andar atual com todos os objetos e etc...
    selectedSpace: null, // Espaço selecionado pelo usuario
    originSpace: null, // Origem selecionado pelo usuario
    destinationSpace: null, // Destino final selecionado pelo usuario
    percentLoaded: 0, // Percentual carregado
    toggleCameraHide: true, // Controlador para esconder botão para alternar camera
    isMultiplan: false, // Controlador para verificar se o shopping é multiplan
  },
  getters: {
    getFloorLevelsDiference(state) {
      const diferenceValue = Math.abs(
        state.currentFloor.level - state.destinationSpace.floor
      );
      if (diferenceValue > 0) {
        return true;
      } else {
        return false;
      }
    },
    getCurrentFloorStairs(state) {
      return state.currentFloor.floorObjects.filter(
        (escalator) => escalator.space.spaceType === 3
      );
    },
    getHighFloorStairs(state, getters) {
      return state.mallData.floors
        .filter((floor) => floor.level === state.currentFloor.level + 1)[0]
        .floorObjects.filter((escalator) =>
          getters.getCurrentFloorStairs.some(
            (stairs) => stairs.spaceId === escalator.spaceId
          )
        );
    },
    getLowerFloorStairs(state, getters) {
      return state.mallData.floors
        .filter((floor) => floor.level === state.currentFloor.level - 1)[0]
        .floorObjects.filter((escalator) =>
          getters.getCurrentFloorStairs.some(
            (stairs) => stairs.spaceId === escalator.spaceId
          )
        );
    },
    getColor(state) {
      return {
        active_spaces: state.currentFloor.activeSpaceColorWeb,
        inactive_spaces: state.currentFloor.inactiveSpaceColorWeb,
        elevators: state.currentFloor.elevatorsSpaceColorWeb,
        escalators: state.currentFloor.stairsSpaceColorWeb,
        hover_space: state.mallData.webHoverSpaceColor,
        origin_space: state.currentFloor.sourceSpaceColorWeb,
        destination_space: state.currentFloor.targetSpaceColorWeb,
        route: state.mallData.webRouteLineColor,
        pictogram: state.mallData.webPictogramColor,
        buttons_background: state.mallData.webButtonBackgroundColor,
        buttons_text: state.mallData.webDestakColor,
        logo_background: state.mallData.webHeaderBoxBackgroundColor,
      };
    },
    getCodeMallData(state) {
      return state.codeMall;
    },
    getMallData(state) {
      return state.mallData;
    },
    getCurrentFloor(state) {
      return state.currentFloor;
    },
    getOriginSpace(state) {
      return state.originSpace;
    },
    getDestinationSpace(state) {
      return state.destinationSpace;
    },
    showMallLogo(state) {
      return state.mallData?.logoWebShow;
    },
    getMallLogo(state) {
      return state.mallData?.logoUrlWeb;
    },
    getAllSpaces(state, getters) {
      const acceptSpacesType = [
        1, 5, 7, 9, 10, 11, 12, 13, 14, 25, 28, 29, 30, 34, 36, 37, 38, 39, 40,
        41, 42, 43, 44, 45, 46,
      ];
      const spaces = getters.getSortedFloors.flatMap((floor) => {
        const header = { header: floor.name };

        const filteredObjects = floor.floorObjects
          .filter((obj) => acceptSpacesType.includes(obj.space.spaceType))
          .sort((a, b) => a.space.name.localeCompare(b.space.name));

        return [header, ...filteredObjects];
      });

      return spaces;
    },
    getSortedFloors(state) {
      if (!state.mallData) return [];
      return state.mallData.floors.sort((a, b) => a.level - b.level);
    },
    getSelectedSpace(state) {
      return state.selectedSpace;
    },
    hideRouteActions(state) {
      return state.toggleCameraHide;
    },
    showCharacter(state) {
      return state.mallData?.webMapShowPersonage;
    },
    getPercentualLoaded(state) {
      return state.percentLoaded;
    },
    isMultiplan(state) {
      return state.isMultiplan;
    },
  },
  mutations: {
    setCurrentFloor(state, floor) {
      state.currentFloor = floor;
    },
    setOrigin(state, payload) {
      if (payload) {
        state.originSpace = {
          floor: payload.floor,
          floorName: payload.space.space.floor,
          objectName: payload.space.objectName,
          spaceName: payload.space.space.name,
          spaceId: payload.space.spaceId,
          pictureUrl: payload.space.space.pictureUrl,
        };
      } else {
        state.originSpace = null;
      }
    },
    setDestination(state, payload) {
      if (payload) {
        state.destinationSpace = {
          floor: payload.floor,
          floorName: payload.space.space.floor,
          objectName: payload.space.objectName,
          spaceName: payload.space.space.name,
          spaceId: payload.space.spaceId,
          pictureUrl: payload.space.space.pictureUrl,
        };
      } else {
        state.destinationSpace = null;
      }
    },
    setCodeMall(state, params) {
      state.codeMall = params;
    },
    setMallData(state, mallData) {
      state.mallData = mallData;
    },
    setFloorByLevel(state, newFloor) {
      state.currentFloor = state.mallData.floors.find(
        (floor) => floor.level === newFloor
      );
    },
    setFloorByOrigin(state) {
      state.currentFloor = state.mallData.floors.find((floor) => {
        floor.level === state.originSpace.floor;
      });
    },
    setFloorByDestination(state) {
      state.currentFloor = state.mallData.floors.find((floor) => {
        floor.level === state.destinationSpace.floor;
      });
    },
    setSelectedSpace(state, payload) {
      if (payload) {
        state.selectedSpace = {
          floor: payload.floor,
          floorName: payload.space.space.floor,
          objectName: payload.space.objectName,
          spaceName: payload.space.space.name,
          spaceId: payload.space.id,
          pictureUrl: payload.space.space.pictureUrl,
        };
      } else {
        state.selectedSpace = null;
      }
    },
    setPercentualLoaded(state, percent) {
      state.percentLoaded = percent;
    },
    toggleCameraHide(state, value) {
      state.toggleCameraHide = value;
    },
    setMultiplan(state, value) {
      state.isMultiplan = value;
    },
  },
  actions: {
    updateSelectedSpace({ commit, getters }, data) {
      if (
        data === null &&
        getters.getOriginSpace &&
        !getters.getDestinationSpace &&
        !getters.isInRoute
      ) {
        const space = getters.getCurrentFloor.floorObjects.find(
          (element) => element.spaceId === getters.getOriginSpace.spaceId
        );
        if (space) {
          commit("setSelectedSpace", {
            space,
            floor: getters.getCurrentFloor.level,
          });
        }
      } else {
        console.log("Nenhum espaço foi escolhido.");
        const space = getters.getCurrentFloor.floorObjects.find(
          (element) => element.space.name === data
        );
        if (space) {
          commit("setSelectedSpace", {
            space,
            floor: getters.getCurrentFloor.level,
          });
        }
      }
    },
    setOrigin({ commit, getters }, origin) {
      return new Promise((resolve) => {
        try {
          if (origin === null) {
            commit("setOrigin", null);
            resolve(null);
            return;
          }
          for (const floor of getters.getMallData.floors) {
            const space = floor.floorObjects.find(
              (obj) =>
                obj.spaceId === origin.spaceId &&
                obj.objectName === origin.objectName
            );

            if (space) {
              commit("setOrigin", { space, floor: floor.level });
              resolve(space);
              return;
            }
          }
          resolve(null);
        } catch (error) {
          console.error(error);
          resolve(null);
          return;
        }
      });
    },
    setDestination({ commit, getters }, destination) {
      return new Promise((resolve) => {
        try {
          if (destination === null) {
            commit("setDestination", null);
            resolve(null);
            return;
          }

          for (const floor of getters.getMallData.floors) {
            const space = floor.floorObjects.find(
              (obj) =>
                obj.spaceId === destination.spaceId &&
                obj.objectName === destination.objectName
            );

            if (space) {
              commit("setDestination", { space, floor: floor.level });
              resolve(space);
              return;
            }
          }
          resolve(null);
        } catch (error) {
          console.error(error);
          resolve(null);
          return;
        }
      });
    },
    setOriginByCode({ commit, getters }, origin) {
      return new Promise((resolve) => {
        try {
          if (origin === null) {
            commit("setOrigin", null);
            resolve(null);
            return;
          }
          for (const floor of getters.getMallData.floors) {
            const space = floor.floorObjects.find(
              (obj) =>
                obj.spaceId === parseInt(origin) ||
                obj.space.code === origin ||
                obj.space.customerId === origin
            );

            if (space) {
              commit("setOrigin", { space, floor: floor.level });
              resolve(space);
              return;
            }
          }
          resolve(null);
        } catch (error) {
          console.error(error);
          resolve(null);
          return;
        }
      });
    },
    setDestinationByCode({ commit, getters }, destination) {
      return new Promise((resolve) => {
        try {
          if (destination === null) {
            commit("setDestination", null);
            resolve(null);
            return;
          }

          for (const floor of getters.getMallData.floors) {
            const space = floor.floorObjects.find(
              (obj) =>
                obj.spaceId === parseInt(destination) ||
                obj.space.code === destination ||
                obj.space.customerId === destination
            );

            if (space) {
              commit("setDestination", { space, floor: floor.level });
              resolve(space);
              return;
            }
          }
          resolve(null);
        } catch (error) {
          console.error(error);
          resolve(null);
          return;
        }
      });
    },
    upLevel({ commit, getters }, level) {
      const newFloor = getters.getSortedFloors.find(
        (floor) => floor.level === getters.getCurrentFloor.level + level
      );

      if (newFloor) {
        commit("setCurrentFloor", newFloor);
      }
    },
    downLevel({ commit, getters }, level) {
      const newFloor = getters.getSortedFloors.find(
        (floor) => floor.level === getters.getCurrentFloor.level - level
      );

      if (newFloor) {
        commit("setCurrentFloor", newFloor);
      }
    },
    setNewFloor({ commit, getters }, level) {
      const newFloor = getters.getSortedFloors.find(
        (floor) => floor.level === level
      );
      if (newFloor) {
        commit("setCurrentFloor", newFloor);
      }
    },
  },
  modules: {
    route,
  },
});
