<template>
  <v-sheet v-if="showSpaceInfo" class="space-info-sheet">
    <v-row no-gutters justify="space-between">
      <v-col class="d-flex flex-column ma-0 pa-0">
        <span class="black--text font-weight-bold mr-2">
          {{ selectedSpace.spaceName }}
        </span>
        <span
          v-if="selectedSpace.floorName !== null"
          class="mr-2"
          :style="{ color: buttonBackground }"
        >
          {{ floor(selectedSpace.floorName) }}
        </span>
      </v-col>
      <v-col v-if="selectedSpace.pictureUrl" cols="5" class="space-picture">
        <v-img
          :src="awsUrl + selectedSpace.pictureUrl"
          width="80"
          height="80"
          contain
        ></v-img>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
export default {
  name: "SpaceInfo",
  data: () => ({
    awsUrl: "https://trackmall.s3.amazonaws.com/", // URL para baixar conteudos na AWS
  }),
  methods: {
    floor(name) {
      const type = /^(?:\d+º Piso)$/.test(name);

      if (type) {
        return name;
      } else {
        return `Piso: ${name}`;
      }
    },
  },
  computed: {
    selectedSpace() {
      if (this.$store.getters.getSelectedSpace) {
        return this.$store.getters.getSelectedSpace;
      } else {
        return this.$store.getters.getOriginSpace;
      }
    },
    buttonBackground() {
      return this.$store.getters.getMallData?.webButtonBackgroundColor;
    },
    showSpaceInfo() {
      if (this.hasOrigin) {
        return (
          this.$store.getters.getSelectedSpace !== null &&
          this.$store.getters.getOriginSpace.floor ===
            this.$store.getters.getCurrentFloor.level
        );
      } else {
        return (
          this.$store.getters.getSelectedSpace !== null ||
          this.$store.getters.getOriginSpace !== null
        );
      }
    },
    originCode() {
      return this.$store.getters.getCodeMallData.origin;
    },
    hasOrigin() {
      return this.originCode !== undefined && this.originCode.length > 0;
    },
  },
  watch: {},
};
</script>
<style scoped>
.space-info-sheet {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 0px 15px 0px #0000002a;
  background-color: rgba(255, 255, 255, 0.796);
  z-index: 1;
  width: 250px;
}
.space-picture {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Mobile */
@media (max-width: 1079px) and (max-height: 1919px) {
  .space-info-sheet {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 0px 15px 0px #0000002a;
    background-color: rgba(255, 255, 255, 0.796);
    z-index: 1;
    width: 250px;
  }
}
</style>
