const state = {
  cancelRoute: false,
  defaultCamera: false,
  paused: false,
  showElevators: false,
  showStairs: false,
  personSpeed: 3, // Velocidade do personagem,
  completeAllPath: false, // Controlador dos botões em relação ao caminho
  inRoute: false, // Controlador para indicar que ainda esta em uma rota
};

const getters = {
  cancelRoute(state) {
    return state.cancelRoute;
  },
  isDefaultCamera(state) {
    return state.defaultCamera;
  },
  isPaused(state) {
    return state.paused;
  },
  isShowingElevators(state) {
    return state.showElevators;
  },
  isShowingStairs(state) {
    return state.showStairs;
  },
  getSpeed(state) {
    return state.personSpeed;
  },
  routeInProgress(state) {
    return state.completeAllPath === false;
  },
  isInRoute(state) {
    return state.inRoute;
  },
};

const mutations = {
  setCancelRoute(state, value) {
    state.cancelRoute = value;
    state.paused = false;
    state.defaultCamera = false;
  },
  toggleCamera(state) {
    state.defaultCamera = !state.defaultCamera;
  },
  togglePause(state) {
    state.paused = !state.paused;
  },
  toggleElevators(state) {
    state.showElevators = !state.showElevators;
  },
  toggleStairs(state) {
    state.showStairs = !state.showStairs;
  },
  setSpeed(state) {
    switch (state.personSpeed) {
      case 3:
        state.personSpeed = 6;
        break;

      case 6:
        state.personSpeed = 9;
        break;

      case 9:
        state.personSpeed = 3;
        break;
    }
  },
  setUniqueSpeed(state, value) {
    state.personSpeed = value;
  },
  toggleCompletePath(state, value) {
    state.completeAllPath = value;
  },
  setIsInRoute(state, value) {
    state.inRoute = value;
  },
  setCamera(state, value) {
    state.defaultCamera = value;
  },
  setPause(state, value) {
    state.paused = value;
  },
};

const actions = {
  changeSpeed(context) {
    context.commit("setSpeed");
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
