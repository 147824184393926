<template>
  <v-row no-gutters class="actions-floor-buttons">
    <v-col cols="auto" class="floor-selection">
      <v-row no-gutters class="floor-card mr-2 mb-4">
        <v-col cols="auto" class="button-text-col">
          <v-row no-gutters class="subtitle" :style="{ color: destakColor }">
            Elevadores
          </v-row>
        </v-col>
        <v-col cols="auto">
          <FloorData
            icon="elevator"
            :bgColor="backgroundColor"
            :txtColor="destakColor"
            :active="isShowingElevators"
            :disabled="$store.getters.routeInProgress"
            @click="toggleElevators"
          />
        </v-col>
      </v-row>
      <v-row no-gutters class="floor-card mr-2 mb-2">
        <v-col cols="auto" class="button-text-col">
          <v-row no-gutters class="subtitle" :style="{ color: destakColor }">
            Escadas
          </v-row>
        </v-col>
        <v-col cols="auto">
          <FloorData
            icon="escalator"
            :bgColor="backgroundColor"
            :txtColor="destakColor"
            :active="isShowingStairs"
            :disabled="$store.getters.routeInProgress"
            @click="toggleStairs"
          />
        </v-col>
      </v-row>
      <v-row no-gutters class="floor-card">
        <v-col cols="auto">
          <v-item-group v-model="selectedFloor" mandatory>
            <v-col v-for="floor in allFloors" :key="floor.name" class="pa-2">
              <v-item v-slot="{ active, toggle }" :value="floor">
                <FloorData
                  :text="floor.media ? null : floor.name"
                  :bgColor="backgroundColor"
                  :txtColor="destakColor"
                  :active="active"
                  :disabled="$store.getters.routeInProgress"
                  @click="toggle"
                />
              </v-item>
            </v-col>
          </v-item-group>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import FloorData from "@/components/floor/tools/FloorData.vue";
export default {
  name: "DesktopFloorData",
  components: { FloorData },
  data: () => ({
    selectedFloor: null,
  }),
  mounted() {
    this.selectedFloor = this.allFloors.find(
      (floor) => floor.level === this.currentFloor.level
    );
  },
  methods: {
    toggleElevators() {
      this.$store.commit("toggleElevators");
    },
    toggleStairs() {
      this.$store.commit("toggleStairs");
    },
  },
  computed: {
    backgroundColor() {
      return this.$store.getters.getMallData?.webButtonBackgroundColor;
    },
    destakColor() {
      return this.$store.getters.getMallData?.webDestakColor;
    },
    isShowingElevators() {
      return this.$store.getters.isShowingElevators;
    },
    isShowingStairs() {
      return this.$store.getters.isShowingStairs;
    },
    allFloors() {
      return this.$store.getters.getSortedFloors;
    },
    currentFloor() {
      return this.$store.getters.getCurrentFloor;
    },
  },
  watch: {
    currentFloor(value) {
      if (value !== this.selectedFloor) {
        this.selectedFloor = value;
      }
    },
    selectedFloor(value) {
      if (value && value.level !== this.currentFloor?.level) {
        this.$store.commit("setCurrentFloor", value);
      }
    },
  },
};
</script>
<style scoped>
.actions-floor-buttons {
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  transform: translateY(-50%);
  margin-right: 16px;
  max-height: 80%;
  overflow-y: scroll;
}
.floor-selection {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
}
.floor-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.button-text-col {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.subtitle {
  font-size: 12px;
  font-weight: 700;
  margin-right: 16px;
}
</style>
