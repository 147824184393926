<template>
  <v-sheet
    class="default-action"
    :style="{ backgroundColor: active ? bgColor : '#ffffff' }"
    elevation="5"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <v-row
      v-if="text"
      ref="textContainer"
      no-gutters
      class="floor-text"
      :justify="shouldAnimate ? 'flex-start' : 'center'"
      :style="{ color: active ? '#ffffff' : txtColor }"
    >
      <span
        ref="textSpan"
        :class="{ 'animated-text': shouldAnimate }"
        :style="shouldAnimate ? animationStyle : {}"
      >
        {{ text }}
      </span>
    </v-row>
    <SVGLoader
      v-else
      :selected-icon="icon"
      class="default-svg"
      :style="{ color: active ? '#ffffff' : txtColor }"
    />
  </v-sheet>
</template>
<script>
import SVGLoader from "@/components/SVGLoader.vue";

export default {
  name: "FloorData",
  components: { SVGLoader },
  props: {
    text: {
      type: String,
    },
    bgColor: {
      type: String,
    },
    txtColor: {
      type: String,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
  },
  data: () => ({
    shouldAnimate: false,
    translateXValue: 0,
  }),
  mounted() {
    this.checkTextOverflow();
  },
  methods: {
    checkTextOverflow() {
      this.$nextTick(() => {
        const textContainer = this.$refs.textContainer;
        const textSpan = this.$refs.textSpan;

        if (textContainer && textSpan) {
          const containerWidth = textContainer.clientWidth;
          const textWidth = textSpan.scrollWidth;

          if (textWidth > containerWidth) {
            this.translateXValue = containerWidth - textWidth - 10; // Margem para evitar cortes
            this.shouldAnimate = true;
          }
        }
      });
    },
  },
  computed: {
    animationStyle() {
      return {
        animation: `slideText 15s linear infinite alternate`,
        "--translateX": `${this.translateXValue}px`,
      };
    },
  },
};
</script>
<style scoped>
.default-action {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px !important;
  width: 60px !important;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
}
.floor-text {
  margin: 16px 8px;
  font-size: 16px;
  font-weight: 500;
  text-wrap: nowrap;
  overflow: hidden;
  text-align: left;
  position: relative;
  width: 100%;
}
.default-svg {
  width: 40px;
  height: 40px;
}

.animated-text {
  display: inline-block;
  white-space: nowrap;
}
</style>
<style>
@keyframes slideText {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(var(--translateX));
  }
  100% {
    transform: translateX(0%);
  }
}
</style>
