<template>
  <v-slide-group
    v-model="selectedFloor"
    mandatory
    center-active
    class="slide-item"
  >
    <v-slide-item v-for="floor in allFloors" :key="floor.name" :value="floor">
      <FloorData
        class="floor-item"
        :text="floor.media ? null : floor.name"
        :bgColor="backgroundColor"
        :txtColor="destakColor"
        :active="active(floor)"
        :disabled="$store.getters.routeInProgress"
        @click="toggle(floor)"
      />
    </v-slide-item>
  </v-slide-group>
</template>
<script>
import FloorData from "@/components/floor/tools/FloorData.vue";
export default {
  name: "MobileFloorActions",
  components: { FloorData },
  data: () => ({
    selectedFloor: null,
  }),
  mounted() {
    if (this.currentFloor) {
      this.selectedFloor = this.allFloors.find(
        (floor) => floor.level === this.currentFloor.level
      );
    }
  },
  methods: {
    toggle(floor) {
      if (floor && floor.level !== this.currentFloor?.level) {
        this.$store.commit("setCurrentFloor", floor);
      }
    },
    active(floor) {
      return floor.level === this.currentFloor?.level;
    },
  },
  computed: {
    backgroundColor() {
      return this.$store.getters.getMallData?.webButtonBackgroundColor;
    },
    destakColor() {
      return this.$store.getters.getMallData?.webDestakColor;
    },
    allFloors() {
      return this.$store.getters.getSortedFloors;
    },
    currentFloor() {
      return this.$store.getters.getCurrentFloor;
    },
  },
  watch: {
    currentFloor(value) {
      if (value !== this.selectedFloor) {
        this.selectedFloor = value;
      }
    },
    selectedFloor(value) {
      if (value && value.level !== this.currentFloor?.level) {
        this.$store.commit("setCurrentFloor", value);
      }
    },
  },
};
</script>
<style>
.slide-item .v-slide-group__content {
  padding: 6px;
}
.floor-item {
  margin-right: 16px;
}
</style>
