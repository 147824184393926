<template>
  <v-autocomplete
    v-model="selected"
    :items="availableSpaces"
    item-text="space.name"
    item-value="objectName"
    return-object
    color="black"
    :item-color="color"
    clearable
    outlined
    hide-details
    hide-no-data
    dense
    label="Aonde você esta?"
    :filter="returnHeadersAndFilter"
    @change="onChange"
    @click:clear="clearSelected"
    :disabled="disabled"
  >
  </v-autocomplete>
</template>
<script>
export default {
  name: "SpaceSelector",
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => ["origin", "destination"].includes(value),
    },
    color: { type: String },
  },
  data: () => ({
    selected: null,
  }),
  mounted() {
    if (this.type === "origin") {
      this.selected = this.$store.getters.getOriginSpace;
    } else {
      this.selected = this.$store.getters.getDestinationSpace;
    }
  },
  methods: {
    onChange(value) {
      if (this.type === "origin") {
        this.$store.dispatch("setOrigin", value);
      } else {
        this.$store.dispatch("setDestination", value);
      }
    },
    clearSelected() {
      if (this.type === "origin") {
        this.$store.dispatch("setOrigin", null);
      } else {
        this.$store.dispatch("setDestination", null);
      }
    },
    returnHeadersAndFilter(item, queryText, itemText) {
      if (item.header) {
        return true;
      }
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
  },
  computed: {
    disabled() {
      return this.$store.getters.routeInProgress;
    },
    availableSpaces() {
      if (this.type === "origin") {
        return this.$store.getters.getAllSpaces.filter(
          (space) => space.objectName !== this.destination || space.header
        );
      } else {
        return this.$store.getters.getAllSpaces.filter(
          (space) => space.objectName !== this.origin || space.header
        );
      }
    },
    origin() {
      return this.$store.getters.getOriginSpace;
    },
    destination() {
      return this.$store.getters.getDestinationSpace;
    },
  },
  watch: {
    origin(value) {
      if (value && this.type === "origin") {
        this.selected = value.objectName;
      } else if (!value && this.type === "origin") {
        this.selected = null;
      }
    },
    destination(value) {
      if (value && this.type === "destination") {
        this.selected = value.objectName;
      } else if (!value && this.type === "destination") {
        this.selected = null;
      }
    },
  },
};
</script>
<style>
.v-subheader.theme--light {
  background-color: var(--mallColor);
  color: white;
  font-weight: bold;
  font-size: large;
}
</style>
