<template>
  <v-card elevation="10" width="300" class="trace-route-card">
    <!-- Mall Logo -->
    <v-container v-if="showLogo" fluid class="logo-container">
      <v-img
        width="100"
        height="50"
        contain
        class="ma-2"
        :src="mallLogoUrl"
      ></v-img>
    </v-container>
    <!-- Origin Selector -->
    <v-row v-if="showOriginSelector" fluid no-gutters class="ma-4 mt-8">
      <space-selector :color="color" type="origin" />
    </v-row>
    <!-- Destination Selector -->
    <v-row v-if="showDestinationSelector" fluid no-gutters class="ma-4">
      <space-selector :color="color" type="destination" />
    </v-row>
    <!-- Start Route -->
    <v-row align="end" justify="end" class="ma-4 pb-2">
      <v-btn
        color="black"
        class="white--text"
        @click="$emit('start')"
        :disabled="disabled"
      >
        Traçar Rota
      </v-btn>
    </v-row>
  </v-card>
</template>
<script>
import SpaceSelector from "@/components/space/tools/SpaceSelector.vue";

export default {
  name: "DesktopSpaceList",
  props: {
    color: { type: String },
  },
  components: { SpaceSelector },
  computed: {
    showLogo() {
      return this.$store.getters.showMallLogo;
    },
    mallLogoUrl() {
      return (
        "https://trackmall.s3.amazonaws.com/" + this.$store.getters.getMallLogo
      );
    },
    showOriginSelector() {
      return this.$store.getters.getAllSpaces.find(
        (space) => space.objectName === this.origin
      );
    },
    showDestinationSelector() {
      return this.$store.getters.getAllSpaces.find(
        (space) => space.objectName === this.destination
      );
    },
    origin() {
      return this.$store.getters.getOriginSpace?.objectName;
    },
    destination() {
      return this.$store.getters.getDestinationSpace?.objectName;
    },
    disabled() {
      return (
        this.$store.getters.routeInProgress ||
        this.origin === null ||
        this.destination === null
      );
    },
  },
};
</script>
<style scoped>
.logo-container {
  display: flex;
  align-items: center;
  justify-items: center;
  background-color: v-bind(color);
}
.trace-route-card {
  position: absolute;
  z-index: 1;
  margin: 8px;
}
</style>
