<template>
  <v-container fluid class="trackmall-container">
    <desktop-space-list
      v-if="isDesktop && codeMallBox"
      :color="trackMallColor"
      @start="chooseAccessRouteOption"
    />
    <space-info />
    <!--
      TODO: Adicionar componentes:
        - Ações de filtro Mobile
    -->
    <desktop-floor-data v-if="isDesktop" />
    <route-actions />
    <v-col
      v-if="percentual > 1"
      cols="4"
      align="center"
      justify="center"
      class="progressBar"
    >
      <v-progress-linear
        :value="percentual"
        striped
        height="10"
        color="green"
      ></v-progress-linear>
    </v-col>
    <THREEJS
      v-if="mallData !== null"
      ref="MapComponent"
      @waitDialog="continuePath = true"
      @saveNextFloor="saveNextFloorName"
      @routeFinish="finishRouteDialog = true"
    />
    <mobile-space-list
      v-if="!isDesktop"
      :color="trackMallColor"
      @start="chooseAccessRouteOption"
    />
    <v-dialog
      v-if="continuePath"
      v-model="continuePath"
      persistent
      max-width="200"
    >
      <v-card class="d-flex flex-column align-center justify-center">
        <span class="dialogText"
          >Você esta saindo do
          <p class="currentFloor">{{ currentFloor.name }}</p>
          e indo para o
          <p class="nextFloor">{{ this.nextFloor.name }}</p></span
        >
        <vue-lottie :options="checkFloorLevel()" :height="50" :width="50" />
        <v-btn
          color="green"
          class="white--text ma-4"
          @click="
            $refs.MapComponent.showNextFloorPatch(), (continuePath = false)
          "
          >Continuar</v-btn
        >
      </v-card>
    </v-dialog>
    <v-dialog v-model="finishRouteDialog" persistent max-width="200">
      <v-card class="d-flex flex-column align-center justify-center">
        <span class="dialogFinishText mt-2">Você chegou ao seu destino!</span>
        <v-card-actions class="d-flex flex-column align-center justify-center">
          <v-btn
            color="green"
            class="white--text mb-2"
            small
            @click="restartRoute"
          >
            Reiniciar
          </v-btn>
          <v-btn
            color="red"
            class="white--text ml-0 mb-2"
            small
            @click="
              $refs.MapComponent.resetAllRouteValues(),
                (finishRouteDialog = false),
                (stopRoute = true)
            "
            >Finalizar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="setAccessRouteDialog" persistent max-width="300">
      <v-card>
        <v-card-title class="dialogFinishText ma-2"
          >Como gostaria de chegar ao seu destino?</v-card-title
        >
        <v-card-text>
          <v-row>
            <v-col align="center">
              <v-card
                width="60"
                height="60"
                elevation="5"
                class="ma-2 d-flex align-center justify-center"
                @click="startRoute()"
              >
                <SVGLoader
                  selected-icon="escalator"
                  class="defaultSVGDesktop"
                  :style="{ color: destakColor }"
                ></SVGLoader>
              </v-card>
              <span class="subtitle" :style="{ color: destakColor }"
                >Escada</span
              >
            </v-col>
            <v-col align="center">
              <v-card
                width="60"
                height="60"
                elevation="5"
                class="ma-2 d-flex align-center justify-center"
                @click="$refs.MapComponent.accessibilityActive(), startRoute()"
              >
                <SVGLoader
                  selected-icon="elevator"
                  class="defaultSVGDesktop"
                  :style="{ color: destakColor }"
                ></SVGLoader>
              </v-card>
              <span class="subtitle" :style="{ color: destakColor }"
                >Elevador</span
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DesktopSpaceList from "@/components/space/DesktopSpaceList.vue";
import MobileSpaceList from "@/components/space/MobileSpaceList.vue";
import SpaceInfo from "@/components/space/tools/SpaceInfo.vue";
import DesktopFloorData from "@/components/floor/DesktopFloorActions.vue";
import RouteActions from "@/components/actions/RouteActions.vue";
import THREEJS from "@/components/THREEJS.vue";
import axios from "axios";
import VueLottie from "vue-lottie";
import upEscalatorIcon from "../../public/assets/up.json";
import downEscalatorIcon from "../../public/assets/down.json";
import SVGLoader from "@/components/SVGLoader.vue";

const _API_MAP = "https://api.trackmall.com.br/mall/";

export default {
  name: "MapViewer",
  components: {
    DesktopSpaceList,
    MobileSpaceList,
    SpaceInfo,
    DesktopFloorData,
    RouteActions,
    THREEJS,
    VueLottie,
    SVGLoader,
  },

  data: () => ({
    accessibility: false, // Botão para ativar acessibilidade
    continuePath: false, // Caixa de dialogo para continuar rota
    stopRoute: true, // Variavel para determinar que a rota foi pausada
    nextFloor: null, // Salvando o nome do proximo andar
    lottieOptions: {
      animationData: null, // Caminho do icone
      loop: true, // ou false para reprodução única
      autoplay: true, // reproduz automaticamente ao carregar
    },
    finishRouteDialog: false, // Dialog que vai dar opções para finalizar rota ou reiniciar
    setAccessRouteDialog: false, // Dialog para determinar o tipo de acesso escada ou elevador
    trackMallColor: "#00ae75", // TrackMall Color
  }),
  beforeMount() {
    this.$store.commit("setCodeMall", this.$route.params); // Salvo os parametros fornecidos na URL

    const isMultiplan = /^[a-zA-ZÀ-ÿ\s]+$/.test(this.$route.params.code);

    if (isMultiplan) {
      this.$store.commit("setMultiplan", true);
    }

    // Caso o parametro com o código do shopping seja fornecido
    if (this.codeMallCode !== null) {
      // Faço uma requisição na API para receber todas as informações do shopping
      axios
        .get(_API_MAP + this.codeMallCode)
        .then((response) => {
          this.$store.commit("setMallData", response.data.result); // Salvo a resposta na variavel

          if (this.$store.getters.getMallData) {
            this.setStartFloor(); // Executo a função para organizar os botôes de andares
            document.title = this.$store.getters.getMallData.name;
            this.topFunction();
            this.updateCSSColor();
          } else {
            this.$router.replace("/");
          }
        })
        .catch((error) => {
          this.$router.replace("/");
          console.log(error);
        });
    } else {
      window.alert("Nenhum código fornecido");
    }
  },
  mounted() {},
  methods: {
    /**
     * @function setStartFloor Função para identificar parametros fornecidos e definir andar inicial
     * @function saveNextFloorName Função que vai salvar o nome do próximo andar
     * @function returnHeadersAndFilter Função para manter cabeçalhos dos andares na lista de busca
     * @function checkFloorLevel Função para carregar o icone de subir ou descer escadas
     * @function chooseAccessRouteOption Função para apresentar opções de escolha de como acessar o proximo andar
     * @function topFunction Rolar página para o topo
     * @function startRoute Função para iniciar a rota
     * @function updateCSSColor Função para atualizar cor padrão dos elementos
     */
    async setStartFloor() {
      if (this.hasOrigin && !this.hasDestination) {
        const result = await this.$store.dispatch(
          "setOriginByCode",
          this.originCode
        );
        if (result) {
          this.$store.dispatch("setNewFloor", this.origin.floor);
          this.$store.dispatch("updateSelectedSpace", this.origin.spaceName);
        } else {
          this.$store.dispatch("setNewFloor", 0);
        }
      } else if (this.hasOrigin && this.hasDestination) {
        await this.$store.dispatch("setOriginByCode", this.originCode);

        const result = await this.$store.dispatch(
          "setDestinationByCode",
          this.destinationCode
        );

        if (result) {
          this.$store.dispatch("setNewFloor", this.destination.floor);
          this.$store.dispatch(
            "updateSelectedSpace",
            this.destination.spaceName
          );
        } else {
          this.$store.dispatch("setNewFloor", 0);
        }
      } else if (!this.hasOrigin && this.hasDestination) {
        const result = await this.$store.dispatch(
          "setDestinationByCode",
          this.destinationCode
        );

        if (result) {
          this.$store.dispatch("setNewFloor", this.destination.floor);
          this.$store.dispatch(
            "updateSelectedSpace",
            this.destination.spaceName
          );
        } else {
          this.$store.dispatch("setNewFloor", 0);
        }
      }
      // Caso nem a origem e nem o destino seja informado
      else {
        this.$store.dispatch("setNewFloor", 0);
      }
    },
    saveNextFloorName(name) {
      this.nextFloor = this.$store.getters.getSortedFloors.find(
        (level) => level.level === name
      );
    },
    returnHeadersAndFilter(item, queryText, itemText) {
      if (item.header) {
        return true;
      }
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    checkFloorLevel() {
      if (this.nextFloor.level > this.currentFloor.level) {
        this.lottieOptions.animationData = upEscalatorIcon;
        return this.lottieOptions;
      } else {
        this.lottieOptions.animationData = downEscalatorIcon;
        return this.lottieOptions;
      }
    },
    chooseAccessRouteOption() {
      if (this.origin.floor !== this.destination.floor) {
        this.setAccessRouteDialog = true;
      } else {
        this.startRoute();
      }
    },
    topFunction() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    startRoute() {
      this.setAccessRouteDialog = false;

      this.$refs.MapComponent.loadOriginSpaceMap();
      this.$refs.MapComponent.pauseRoute();
    },
    updateCSSColor() {
      this.trackMallColor =
        this.$store.getters.getMallData.webButtonBackgroundColor;
      const root = document.querySelector(":root");
      root.style.setProperty(`--mallColor`, this.trackMallColor);
    },
    restartRoute() {
      this.$store.commit("setCamera", false);
      this.$store.commit("setPause", false);
      this.$refs.MapComponent.reloadExclusiveAccess();
      this.$refs.MapComponent.loadOriginSpaceMap();
      this.$refs.MapComponent.pauseRoute();
      this.finishRouteDialog = false;
      this.stopRoute = true;
    },
  },
  watch: {},
  computed: {
    isDesktop() {
      return !this.$vuetify.breakpoint.mobile;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    mallData() {
      return this.$store.getters.getMallData;
    },
    destakColor() {
      return this.mallData?.webDestakColor;
    },
    currentFloor() {
      return this.$store.getters.getCurrentFloor;
    },
    origin() {
      return this.$store.getters.getOriginSpace;
    },
    destination() {
      return this.$store.getters.getDestinationSpace;
    },
    originCode() {
      return this.$store.getters.getCodeMallData.origin;
    },
    destinationCode() {
      return this.$store.getters.getCodeMallData.destino;
    },
    codeMallBox() {
      return this.$store.getters.getCodeMallData.box;
    },
    codeMallCode() {
      return this.$store.getters.getCodeMallData.code;
    },
    percentual() {
      return this.$store.getters.getPercentualLoaded;
    },
    hasOrigin() {
      return this.originCode !== undefined && this.originCode.length > 0;
    },
    hasDestination() {
      return (
        this.destinationCode !== undefined && this.destinationCode.length > 0
      );
    },
  },
};
</script>
<style>
:root {
  --mallColor: #00ae75;
}
.trackmall-container {
  height: 100%;
  padding: 0vh;
}
.progressBar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.dialogText {
  margin: 8px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
}
.dialogFinishText {
  margin: 8px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}
.currentFloor {
  color: #005d3e;
  font-weight: 800;
  font-size: 18px;
  margin-bottom: 4px !important;
}
.nextFloor {
  color: red;
  font-weight: 800;
  font-size: 18px;
  margin-bottom: 4px !important;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background: var(--mallColor);
  border-radius: 50px;
}
</style>
