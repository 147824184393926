<template>
  <desktop-actions v-if="isDesktop" />
  <mobile-actions v-else />
</template>
<script>
import DesktopActions from "@/components/actions/tools/DesktopActions.vue";
import MobileActions from "@/components/actions/tools/MobileActions.vue";
export default {
  name: "RouteActions",
  components: {
    DesktopActions,
    MobileActions,
  },
  computed: {
    isDesktop() {
      return !this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>
<style scoped></style>
