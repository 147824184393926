<template>
  <v-card
    elevation="10"
    class="actions-card"
    :class="{ 'hide-actions': hideActions }"
  >
    <v-row class="ma-2">
      <v-col class="button-col">
        <actions-button
          type="camera"
          :active="isDefaultCamera"
          :bgColor="backgroundColor"
          :txtColor="destakColor"
          @click="toggleCamera"
        />
      </v-col>
      <v-col class="button-col mx-4">
        <actions-button
          type="stream"
          :active="isPaused"
          :bgColor="backgroundColor"
          :txtColor="destakColor"
          @click="togglePause"
        />
      </v-col>
      <v-col class="button-col mr-4">
        <actions-button
          type="speed"
          :bgColor="backgroundColor"
          :txtColor="destakColor"
          :showIconTitle="true"
          @click="$store.dispatch('changeSpeed')"
        />
      </v-col>
      <v-col class="button-col">
        <actions-button
          type="cancel"
          :active="true"
          bgColor="#ff0000"
          :txtColor="destakColor"
          @click="cancel"
        />
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import ActionsButton from "@/components/actions/utils/ActionsButton.vue";
export default {
  name: "DesktopActions",
  components: { ActionsButton },
  methods: {
    toggleCamera() {
      this.$store.commit("toggleCamera");
    },
    togglePause() {
      this.$store.commit("togglePause");
    },
    cancel() {
      this.$store.commit("setCancelRoute", true);
    },
  },
  computed: {
    hideActions() {
      return this.$store.getters.hideRouteActions;
    },
    backgroundColor() {
      return this.$store.getters.getMallData?.webButtonBackgroundColor;
    },
    destakColor() {
      return this.$store.getters.getMallData?.webDestakColor;
    },
    isDefaultCamera() {
      return this.$store.getters.isDefaultCamera;
    },
    isPaused() {
      return this.$store.getters.isPaused;
    },
    setSpeedIcon() {
      switch (this.$store.getters.getSpeed) {
        case 3:
          return "mdi-speedometer-slow";

        case 6:
          return "mdi-speedometer-medium";

        default:
          return "mdi-speedometer";
      }
    },
  },
};
</script>
<style scoped>
.hide-actions {
  display: none !important;
}
.actions-card {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 8px;
  background-color: white !important;
  border-radius: 10px !important;
  z-index: 2;
}
.button-col {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
