<template>
  <v-bottom-sheet
    v-model="showSearchCard"
    persistent
    hide-overlay
    no-click-animation
    inset
    width="90%"
  >
    <v-sheet>
      <v-row
        v-if="showMallLogo"
        no-gutters
        :style="{ backgroundColor: bgColor }"
        class="logo-row"
      >
        <v-col cols="auto" v-if="showMallLogo" />
        <v-col cols="auto" v-if="showMallLogo">
          <v-img
            width="100"
            height="50"
            contain
            class="ma-1"
            :src="mallLogo"
            @click="displayRoute = false"
          ></v-img>
        </v-col>
        <v-col cols="auto">
          <v-icon
            v-if="displayRoute && showMallLogo"
            large
            color="#ffffff"
            @click="displayRoute = false"
          >
            {{ displayRoute ? "mdi-chevron-down" : "mdi-chevron-up" }}
          </v-icon>
        </v-col>
      </v-row>

      <v-row v-if="!displayRoute" no-gutters justify="end">
        <v-btn
          color="black"
          class="white--text ma-2"
          small
          @click="displayRoute = true"
        >
          Traçar Rota
        </v-btn>
      </v-row>

      <v-container v-else fluid>
        <!-- Origin Selector -->
        <space-selector
          v-if="displayRoute && showOriginSelector"
          :color="color"
          type="origin"
          class="mb-3"
        />
        <!-- Destination Selector -->
        <space-selector
          v-if="displayRoute && showDestinationSelector"
          :color="color"
          type="destination"
        />

        <!-- Start Route -->
        <v-row v-if="displayRoute" justify="end" class="mt-3 pa-0">
          <v-btn
            color="black"
            class="white--text mr-4 mb-4"
            small
            @click="$emit('start')"
            :disabled="disabled"
          >
            Buscar
          </v-btn>
        </v-row>
      </v-container>
    </v-sheet>
  </v-bottom-sheet>
</template>
<script>
import SpaceSelector from "@/components/space/tools/SpaceSelector.vue";
export default {
  name: "MobileSpaceList",
  props: {
    color: { type: String },
  },
  components: { SpaceSelector },
  data: () => ({
    displayRoute: false,
  }),
  computed: {
    showSearchCard() {
      return (
        this.$store.getters.getCodeMallData.box &&
        !this.$store.getters.routeInProgress
      );
    },
    showMallLogo() {
      return this.$store.getters.getMallData?.logoWebShow;
    },
    mallLogo() {
      return (
        "https://trackmall.s3.amazonaws.com/" + this.$store.getters.getMallLogo
      );
    },
    origin() {
      return this.$store.getters.getOriginSpace?.objectName;
    },
    destination() {
      return this.$store.getters.getDestinationSpace?.objectName;
    },
    routeInProgress() {
      return this.$store.getters.routeInProgress;
    },
    logoWebShow() {
      return this.$store.state.mallData.logoWebShow;
    },
    disabled() {
      return this.routeInProgress || !this.origin || !this.destination;
    },
    bgColor() {
      return this.$store.getters.getMallData?.webHeaderBoxBackgroundColor;
    },
    showOriginSelector() {
      return this.$store.getters.getAllSpaces.find(
        (space) => space.objectName === this.origin
      );
    },
    showDestinationSelector() {
      return this.$store.getters.getAllSpaces.find(
        (space) => space.objectName === this.destination
      );
    },
  },
};
</script>
<style scoped>
.logo-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
}
</style>
